import React from "react";
import css from "./home.module.css";
export const Home = () => {
  return (
    <div className={css.container}>
      <div className={css.homeButtons}>
        <span className={css.buttonTile}>Add Patient </span>
        <span className={css.buttonTile}>Add Recipe</span>
        <span className={css.buttonTile}>Add Report</span>
        <span className={css.buttonTile}>Add Patient</span>
        <span className={css.buttonTile}>Add Recipe</span>
        <span className={css.buttonTile}>Add Report</span>
      </div>
      <div className={css.homeTable}>the table</div>
    </div>
  );
};
