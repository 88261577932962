import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { ingredientsData } from "../../../data/sample-data";
import { Dropdown, InputField, LabelText } from "../../common/components";
import Button from "../../common/components/button";
import CSS from "./recipes.module.css";
import { Field, Form, Formik } from "formik";
import { Recipe, RecipeTagsOptions, Units } from "../../../types/recipes";
import { DashboardContext } from "../../dashboard/dashboard-context";
import { Table, TableRow } from "../../common/components/table";

const darkThemeStyles = {
  control: (provided: any) => ({
    ...provided,
    backgroundColor: "#333", // Dark background
    color: "#172B4D", // Text color
    borderColor: "#555", // Lighter border color
  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: "#333", // Dark background for the dropdown
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#555" : "#333", // Different background for selected option
    color: "white", // Text color
    "&:hover": {
      backgroundColor: "#555", // Background for hovering
    },
  }),
  multiValue: (provided: any) => ({
    ...provided,
    backgroundColor: "#555", // Background for selected options tags
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    color: "white", // Text color for selected options tags
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    color: "white", // Text color for the remove icon in selected options tags
    "&:hover": {
      backgroundColor: "#777", // Background color for the remove icon on hover
      color: "white", // Text color for the remove icon on hover
    },
  }),
};

type IngredientType = { value: string; label: string };

export const Recipes = () => {
  const [showModal, setShowModal] = useState(false);

  const { setActiveSection, editRecipe } = useContext(DashboardContext);

  const [selectedIngredients, setSelectedIngredients] = useState<
    IngredientType[]
  >(
    editRecipe?.ingredients.map((ing) => ({
      value: ing.ingredientName,
      label: ing.ingredientName,
    })) ?? []
  );

  const [selectedTags, setSelectedTags] = useState<IngredientType[]>(
    editRecipe?.tags?.map((tag) => ({
      value: tag,
      label: tag,
    })) ?? []
  );

  const [initialObject, setInitialObject] = useState<Recipe>({
    id: "1",
    name: "",
    ingredients: [],
    instructions: "",
  });
  useEffect(() => {
    setInitialObject({
      id: "1",
      name: "",
      ingredients: selectedIngredients.map((ingredient) => ({
        ingredientName: ingredient.label,
        quantity: 0,
        unit: "g",
        description: "",
      })),
      instructions: "",
    });
  }, [selectedIngredients]);

  return (
    <Formik
      initialValues={editRecipe ? editRecipe : initialObject}
      onSubmit={(values) => {
        console.log(values);
      }}
    >
      {({ setFieldValue, values }) => (
        <Form className={CSS.form}>
          <div className={CSS.container}>
            <span className={CSS.formField}>
              <LabelText text="Recipe Name" />
              <Field name="name" as={InputField} />
            </span>

            <span className={CSS.formField}>
              <LabelText text="Ingredients" />
              <Select
                isMulti
                onChange={(value) => {
                  setSelectedIngredients(value as IngredientType[]);
                  setFieldValue(
                    "ingredients",
                    (value as IngredientType[]).map((ingredient) => ({
                      ingredientName: ingredient.label,
                      quantity:
                        values.ingredients.find(
                          (ing) => ing.ingredientName === ingredient.label
                        )?.quantity ?? 0,
                      unit:
                        values.ingredients.find(
                          (ing) => ing.ingredientName === ingredient.label
                        )?.unit ?? "g",
                      description:
                        values.ingredients.find(
                          (ing) => ing.ingredientName === ingredient.label
                        )?.description ?? "",
                    }))
                  );
                }}
                options={ingredientsData}
                styles={darkThemeStyles}
                value={
                  selectedIngredients.length > 0
                    ? selectedIngredients
                    : undefined
                }
              />
            </span>
            <Table height={80}>
              <TableRow
                isHeader={true}
                columns={[
                  { data: "Sr.", width: 5 },
                  { data: "Ingredient Name", width: 30 },
                  { data: "Quantity", width: 20 },
                  { data: "Unit", width: 20 },
                  { data: "Description", width: 25 },
                ]}
              />

              {values.ingredients.map((ingredient, index) => (
                <TableRow
                  isHeader={false}
                  columns={[
                    { data: index + 1 + "", width: 5 },
                    { data: ingredient.ingredientName, width: 30 },
                    {
                      child: (
                        <span>
                          <Field
                            type="number"
                            as={InputField}
                            name={`ingredients[${index}].quantity`}
                          />
                        </span>
                      ),
                      width: 20,
                    },
                    {
                      child: (
                        <span className={CSS.selectContainer}>
                          <Dropdown
                            label="Unit"
                            options={Units.map((unit) => unit.value)}
                            onChange={(e) => {
                              setFieldValue(
                                `ingredients[${index}].unit`,
                                e.target.value
                              );
                            }}
                          />
                        </span>
                      ),
                      width: 20,
                    },
                    {
                      child: (
                        <Field
                          as={InputField}
                          name={`ingredients[${index}].description`}
                          placeholder="e.g. chopped, sliced, etc."
                        />
                      ),
                      width: 25,
                    },
                  ]}
                />
              ))}
            </Table>

            <span className={CSS.formField}>
              <LabelText text="Recipe Tags (Diagnostics or Findings)" />
              <Select
                isMulti
                onChange={(value) => {
                  setSelectedTags(value as IngredientType[]);

                  setFieldValue(
                    "tags",
                    value.map((v) => v.value)
                  );
                }}
                options={RecipeTagsOptions}
                styles={darkThemeStyles}
                value={selectedTags.length > 0 ? selectedTags : undefined}
              />
            </span>

            <span className={CSS.formField}>
              <LabelText text="Instructions (optional)" />
              <Field
                multiline
                placeholder=""
                as={InputField}
                name="instructions"
              />
            </span>
          </div>
          <div className={CSS.buttonRow}>
            {editRecipe ? (
              <Button
                mode="primary"
                label="Update Recipe"
                type="submit"
                onClick={() => {
                  setShowModal(!showModal);
                }}
              />
            ) : (
              <Button
                mode="primary"
                label="Save Recipe"
                type="submit"
                onClick={() => {
                  setShowModal(!showModal);
                }}
              />
            )}
            <Button
              mode="secondary"
              label="Cancel"
              type="button"
              onClick={() => {
                setActiveSection("Recipes");
              }}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};
