import { Patient } from "../types/patients";

export const samplePatients = [
  {
    id: "1",
    name: "John Doe",
    age: 30,
    bloodType: "A",
    weight: 70,
    phoneNumber: "1234567890",
    email: "john.doe@gmail.com",
    address: "123 Main St",
    referredBy: "Dr. Smith",
    patientSince: new Date("2020-01-01"),
    visits: [],
    history: [
      { name: "Fever", duration: 5 },
      { name: "Cough", duration: 3 },
    ],
    allergies: "Peanuts",
    diagnosis: "Common Cold",
  },
  {
    id: "2",
    name: "Jane Doe",
    age: 28,
    bloodType: "B",
    weight: 65,
    phoneNumber: "1234567891",
    email: "jane.doe@gmail.com",
    address: "456 Elm St",
    referredBy: "Dr. Adams",
    patientSince: new Date("2019-03-15"),
    visits: [],
    history: [
      { name: "Fever", duration: 5 },
      { name: "Cough", duration: 3 },
      { name: "Headache", duration: 2 },
    ],
    allergies: "Peanuts",
    diagnosis: "Cold and Fever Viral",
  },
  {
    id: "3",
    name: "Alice Johnson",
    age: 45,
    bloodType: "AB",
    weight: 80,
    phoneNumber: "1234567892",
    email: "alice.johnson@gmail.com",
    address: "789 Pine St",
    referredBy: "Dr. Brown",
    patientSince: new Date("2018-07-22"),
    visits: [],
  },
  {
    id: "4",
    name: "Bob Smith",
    age: 50,
    bloodType: "O",
    weight: 90,
    phoneNumber: "1234567893",
    email: "bob.smith@gmail.com",
    address: "101 Maple St",
    referredBy: "Dr. Green",
    patientSince: new Date("2017-05-10"),
    visits: [],
  },
  {
    id: "5",
    name: "Carol White",
    age: 35,
    bloodType: "A",
    weight: 68,
    phoneNumber: "1234567894",
    email: "carol.white@gmail.com",
    address: "202 Oak St",
    referredBy: "Dr. Black",
    patientSince: new Date("2016-11-18"),
    visits: [],
  },
  {
    id: "6",
    name: "David Brown",
    age: 40,
    bloodType: "B",
    weight: 85,
    phoneNumber: "1234567895",
    email: "david.brown@gmail.com",
    address: "303 Cedar St",
    referredBy: "Dr. White",
    patientSince: new Date("2015-04-02"),
    visits: [],
  },
  {
    id: "7",
    name: "Emma Wilson",
    age: 32,
    bloodType: "AB",
    weight: 75,
    phoneNumber: "1234567896",
    email: "emma.wilson@gmail.com",
    address: "404 Birch St",
    referredBy: "Dr. Gray",
    patientSince: new Date("2014-08-25"),
    visits: [],
  },
  {
    id: "8",
    name: "Frank Green",
    age: 55,
    bloodType: "O",
    weight: 95,
    phoneNumber: "1234567897",
    email: "frank.green@gmail.com",
    address: "505 Ash St",
    referredBy: "Dr. Blue",
    patientSince: new Date("2013-12-13"),
    visits: [],
  },
  {
    id: "9",
    name: "Grace Lee",
    age: 29,
    bloodType: "A",
    weight: 72,
    phoneNumber: "1234567898",
    email: "grace.lee@gmail.com",
    address: "606 Walnut St",
    referredBy: "Dr. Red",
    patientSince: new Date("2012-10-30"),
    visits: [],
  },
  {
    id: "10",
    name: "Henry Clark",
    age: 37,
    bloodType: "B",
    weight: 78,
    phoneNumber: "1234567899",
    email: "henry.clark@gmail.com",
    address: "707 Chestnut St",
    referredBy: "Dr. Violet",
    patientSince: new Date("2011-06-05"),
    visits: [],
  },
] as Patient[];
