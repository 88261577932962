import React from "react";

import "./App.css";
//import { Login } from "./features/login";
import { Dashboard } from "./features/dashboard";
import { DashboardProvider } from "./features/dashboard/dashboard-context";

function App() {
  return (
    <div className="App">
      <DashboardProvider>
        <Dashboard />
      </DashboardProvider>
    </div>
  );
}

export default App;
