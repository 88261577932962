import React from "react";
import CSS from "./components.module.css";

const defaultProps = {
  label: "DropDown",
  options: [],
};

type DropdownProps = {
  label?: string;
  options?: string[];
};

export const Dropdown = ({
  label,
  options,
  ...props
}: DropdownProps & React.SelectHTMLAttributes<HTMLSelectElement>) => {
  return (
    <select {...props} className={CSS.dropdown} defaultValue="">
      <option value="" disabled hidden>
        {label ?? defaultProps.label}
      </option>
      {(options ?? defaultProps.options).map((option: string) => (
        <option value={option} key={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

type InputFieldProps = {
  multiline?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>;

export const InputField: React.FC<InputFieldProps> = ({
  className,
  ...inputProps
}) => {
  return {
    ...(inputProps.multiline ? (
      <textarea
        style={{
          width: inputProps ? `${inputProps.width}px` : "100%",
          height: "100px",
        }}
        className={`${CSS.input} ${className}`}
        {...inputProps}
      />
    ) : (
      <input
        data-type={inputProps.type}
        style={{
          width: inputProps ? `${inputProps.width}px` : "100%",
        }}
        className={`${CSS.input} ${className}`}
        {...inputProps}
      />
    )),
  };
};

interface LabelTextProps {
  text?: string;
}

export const LabelText = (props: LabelTextProps) => {
  return <div className={CSS.Text}>{props.text ?? "Default Label!"}</div>;
};
