import React from "react";
import CSS from "./modal.module.css";
import Button from "../components/button";

type ModalProps = {
  onClose?: () => void;
  onCloseText?: string;
  onSave?: () => void;
  children: React.ReactNode;
  variant?: "default" | "small";
};
const Modal = ({
  onCloseText,
  onClose,
  onSave,
  children,
  variant = "default",
}: ModalProps) => {
  return (
    <div className={CSS.modal}>
      <div className={CSS.modalBody} data-variant={variant}>
        {children}
        {/* <div className={CSS.modalFooter}> */}
        {onClose && <Button label={onCloseText ?? "Close"} onClick={onClose} />}
        {/* {onSave && <Button label="Save" onClick={onSave} />} */}
        {/* </div> */}
      </div>
    </div>
  );
};
export default Modal;
