import React, { ReactNode } from "react";
import { LabelText } from "..";
import CSS from "./table.module.css";

export const Table = ({
  children,
  height = 100,
}: {
  children: ReactNode;
  height?: number;
}) => {
  return (
    <div style={{ maxHeight: `${height}%` }} className={CSS.table}>
      {children}
    </div>
  );
};

type ColumnData = {
  width?: number;
  data?: string | number;
  child?: ReactNode;
};

type TableRowProps = {
  isHeader?: boolean;
  columns: ColumnData[];
  minHeight?: number;
};
export const TableRow = ({
  isHeader = false,
  columns,
  minHeight,
}: TableRowProps) => {
  return (
    <li
      style={{ minHeight: minHeight ?? 0 }}
      className={CSS.listRow}
      data-header={isHeader}
    >
      {columns.map((column, index) => (
        <span
          key={index}
          style={{ width: column.width ? `${column.width}%` : "auto" }}
        >
          {column.data && <LabelText text={column.data + ""} />}
          {column.child}
        </span>
      ))}
    </li>
  );
};
