import React from "react";
import CSS from "./button.module.css";

type ButtonProps = {
  mode?: "primary" | "secondary";
  label?: string;
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button: React.FC<ButtonProps> = ({
  mode = "primary",
  label = "Button",
  type = "button",
  ...props
}) => {
  return (
    <button
      data-secondary={mode === "secondary"}
      className={CSS.button}
      type={type}
      {...props}
    >
      {label}
    </button>
  );
};

export default Button;
