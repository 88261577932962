import { ReportType } from "../types/reports";

export const sampleReports: ReportType[] = [
  {
    id: "1",
    patient: {
      id: "10",
      name: "Henry Clark",
      age: 37,
      bloodType: "B",
      weight: 78,
      phoneNumber: "1234567899",
      email: "henry.clark@gmail.com",
      address: "707 Chestnut St",
      referredBy: "Dr. Violet",
      patientSince: new Date("2011-06-05"),
      visits: [],
    },
    breakfast: {
      recipes: [],
      instructions: [
        {
          title: "Breakfast",
          details: "1 cup of oatmeal with 1 cup of milk and 1 apple",
          duration: 10,
        },
      ],
      ingredients: ["oatmeal", "milk", "apple"],
      avoids: {
        meat: [],
        seafood: [],
        vegetablesAndFruits: ["banana", "orange"],
        others: ["sugar"],
      },
    },
    lunch: {
      recipes: [],
      instructions: [
        {
          title: "Lunch",
          details: "1 cup of rice with 1 cup of lentils and 1 orange",
          duration: 10,
        },
      ],
      ingredients: ["rice", "lentils", "orange"],
      avoids: {
        meat: [],
        seafood: [],
        vegetablesAndFruits: ["banana", "apple"],
        others: ["sugar"],
      },
    },
    dinner: {
      recipes: [],
      instructions: [
        {
          title: "Dinner",
          details: "1 cup of rice with 1 cup of lentils and 1 orange",
          duration: 10,
        },
      ],
      ingredients: ["rice", "lentils", "orange"],
      avoids: {
        meat: [],
        seafood: [],
        vegetablesAndFruits: ["banana", "apple"],
        others: ["sugar"],
      },
    },
  },
  {
    id: "2",
    patient: {
      id: "9",
      name: "Grace Lee",
      age: 29,
      bloodType: "A",
      weight: 72,
      phoneNumber: "1234567898",
      email: "",
      address: "606 Walnut St",
      referredBy: "Dr. Red",
      patientSince: new Date("2012-10-30"),
      visits: [],
    },
    breakfast: {
      recipes: [],
      instructions: [
        {
          title: "Breakfast",
          details: "1 cup of oatmeal with 1 cup of milk and 1 apple",
          duration: 10,
        },
      ],
      ingredients: ["oatmeal", "milk", "apple"],
      avoids: {
        meat: [],
        seafood: [],
        vegetablesAndFruits: ["banana", "orange"],
        others: ["sugar"],
      },
    },
    lunch: {
      recipes: [],
      instructions: [
        {
          title: "Lunch",
          details: "1 cup of rice with 1 cup of lentils and 1 orange",
          duration: 10,
        },
      ],
      ingredients: ["rice", "lentils", "orange"],
      avoids: {
        meat: [],
        seafood: [],
        vegetablesAndFruits: ["banana", "apple"],
        others: ["sugar"],
      },
    },
    dinner: {
      recipes: [],
      instructions: [
        {
          title: "Dinner",
          details: "1 cup of rice with 1 cup of lentils and 1 orange",
          duration: 10,
        },
      ],
      ingredients: ["rice", "lentils", "orange"],
      avoids: {
        meat: [],
        seafood: [],
        vegetablesAndFruits: ["banana", "apple"],
        others: ["sugar"],
      },
    },
  },
];
