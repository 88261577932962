import { useContext, useState } from "react";
import { ReportsContext, SectionsType } from "../reports-context";
import { DashboardContext } from "../../../dashboard/dashboard-context";
import Button from "../../../common/components/button";
import { Breakfast } from "../pages/breakfast";
import { Lunch } from "../pages/lunch";
import { Dinner } from "../pages/dinner";
import { Drinks } from "../pages/drinks";
import CSS from "./common.module.css";
import { PatientInformation } from "../pages/patient-information";

export const Sidebar = () => {
  const { activeSectionReport, setActiveSectionReport } =
    useContext(ReportsContext);
  const { setActiveSection } = useContext(DashboardContext);

  return (
    <div className={CSS.sidebar}>
      <div className={CSS.sidebarNavigation}>
        <span
          data-active={activeSectionReport === "Patient-Information"}
          onClick={() => {
            setActiveSectionReport("Patient-Information");
          }}
          className={CSS.sidebarButton}
        >
          Patient Information
        </span>
        <span
          data-active={activeSectionReport === "Breakfast"}
          onClick={() => {
            setActiveSectionReport("Breakfast");
          }}
          className={CSS.sidebarButton}
        >
          Breakfast
        </span>
        <span
          data-active={activeSectionReport === "Lunch"}
          onClick={() => {
            setActiveSectionReport("Lunch");
          }}
          className={CSS.sidebarButton}
        >
          Lunch
        </span>

        <span
          onClick={() => {
            setActiveSectionReport("Dinner");
          }}
          data-active={activeSectionReport === "Dinner"}
          className={CSS.sidebarButton}
        >
          Dinner
        </span>

        <span
          onClick={() => {
            setActiveSectionReport("Drinks");
          }}
          data-active={activeSectionReport === "Drinks"}
          className={CSS.sidebarButton}
        >
          Drinks
        </span>
      </div>
      <div className={CSS.buttonRow}>
        <Button
          onClick={() => {
            setActiveSection("Home");
          }}
          label="Close"
        />
      </div>
    </div>
  );
};

type NavButtonProps = {
  activeSection: SectionsType;
  setNextSection: (section: SectionsType) => void;
  setPreviousSection: (section: SectionsType) => void;
};
export const NavButtons = ({
  activeSection,
  setNextSection,
  setPreviousSection,
}: NavButtonProps) => {
  return (
    <div className={CSS.navButtons}>
      <span>
        {activeSection !== "Patient-Information" && (
          <Button
            label="Back"
            onClick={() => {
              setPreviousSection(activeSection);
            }}
          />
        )}
      </span>
      <Button
        label="Next"
        onClick={() => {
          setNextSection(activeSection);
        }}
      />
    </div>
  );
};

export const CollapsableDiv = ({
  title,
  children,
}: {
  title: string;
  children: any;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={CSS.collapsableDivContainer} data-isOpen={isOpen}>
      <div
        className={CSS.collapsableDivHeader}
        onClick={() => setIsOpen(!isOpen)}
        data-isOpen={isOpen}
      >
        <h3>{title}</h3>
      </div>
      <div data-isOpen={isOpen} className={CSS.collapsableDivBody}>
        {isOpen && children}
      </div>
    </div>
  );
};

// This function is used to render the section based on the active section

export function renderSection(section: string) {
  switch (section) {
    case "Patient-Information":
      return <PatientInformation />;
    case "Breakfast":
      return <Breakfast />;
    case "Lunch":
      return <Lunch />;
    case "Dinner":
      return <Dinner />;
    case "Drinks":
      return <Drinks />;
    default:
      return <Breakfast />;
  }
}
