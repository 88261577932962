import React, { useContext, useEffect, useState } from "react";
import CSS from "./patients.module.css"; // Ensure correct path to your CSS module
import { samplePatients } from "../../../data/sample-patients";
import { Table, TableRow } from "../../common/components/table";
import Button from "../../common/components/button";
import { InputField, LabelText } from "../../common/components";
import { DashboardContext } from "../../dashboard/dashboard-context";
import { Patient } from "../../../types/patients";

type AllPatientsProps = {
  pageMode?: "all-patients" | "patient-report";
  setPatient?: (patient: Patient) => void;
};

export const AllPatients = ({
  pageMode = "all-patients",
  setPatient,
}: AllPatientsProps) => {
  const [filteredPatients, setFilteredPatients] = useState(samplePatients);
  const { setActiveSection, setEditPatient } = useContext(DashboardContext);

  //CLearing any edit patient data to be on the safe side
  useEffect(() => {
    setEditPatient(undefined);
  }, []);

  console.log("All Patients", pageMode);
  return (
    <div className={CSS.form}>
      <div className={CSS.allRecipesHeader}>
        <span className={CSS.formField}>
          <LabelText text="Search" />
          <InputField
            width={400}
            placeholder="Search for patients (Name, Blood Type, Age, Phone number)"
            onChange={(value) => {
              if (value.target.value && value.target.value !== "") {
                const filtered = samplePatients.filter(
                  (patient) =>
                    patient.name
                      .toLowerCase()
                      .includes(value.target.value.toLowerCase()) ||
                    patient.age === +value.target.value ||
                    patient.bloodType === value.target.value ||
                    patient.phoneNumber === value.target.value
                );
                setFilteredPatients(filtered);
              } else setFilteredPatients(samplePatients);
            }}
          />
        </span>

        {pageMode !== "patient-report" && (
          <Button
            label="Add Patient"
            onClick={() => {
              setActiveSection("add-patient");
            }}
          />
        )}
      </div>
      <Table>
        <TableRow
          isHeader
          columns={[
            { data: "Sr#", width: 3 },
            { data: "Name", width: 15 },
            { data: "Age", width: 5 },
            { data: "Blood Type", width: 5 },

            { data: "Phone Number", width: 10 },
            { data: "Email", width: 15 },
            { data: "Address", width: 10 },
            { data: "Referred By", width: 10 },
            { data: "Patient Since", width: 10 },
            { data: "Actions", width: 5 },
          ]}
        />
        {filteredPatients.map((patient, index) => (
          <span
            onClick={() => {
              if (pageMode === "patient-report") {
                setPatient && setPatient(patient);
                return;
              } else {
                setActiveSection("edit-patient");
                setEditPatient && setEditPatient(patient);
              }
            }}
          >
            <TableRow
              key={patient.id}
              columns={[
                { data: index + 1, width: 3 },
                { data: patient.name, width: 15 },
                { data: patient.age, width: 5 },
                { data: patient.bloodType, width: 5 },

                { data: patient.phoneNumber, width: 10 },
                { data: patient.email, width: 15 },
                { data: patient.address, width: 10 },
                { data: patient.referredBy, width: 10 },
                { data: "01-01-2021", width: 10 },
                // Todo: make a updated version of button to have a smaller size
                { child: <Button label="Details" />, width: 5 },
              ]}
            />
          </span>
        ))}
      </Table>
    </div>
  );
};
