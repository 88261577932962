type Unit =
  | "g"
  | "kg"
  | "ml"
  | "l"
  | "tsp"
  | "tbsp"
  | "cup"
  | "pint"
  | "quart"
  | "gallon"
  | "oz"
  | "lb"
  | "mg"
  | "mcg"
  | "unit"
  | "quantity";

export type DiagnosisTags =
  | "Dehydration"
  | "Lack of Good Fats"
  | "Liver"
  | "Cholesterol"
  | "Liver Crystals"
  | "Kidney Crystals"
  | "Parasites"
  | "Candida"
  | "Fermentation"
  | "Hormones"
  | "Digestion"
  | "Lack of Vitamins"
  | "B12"
  | "Iron"
  | "D3"
  | "Minerals"
  | "Clots"
  | "CVD Percentage"
  | "Immune System";

export const RecipeTagsOptions = [
  { value: "Dehydration", label: "Dehydration" },
  { value: "Lack of Good Fats", label: "Lack of Good Fats" },
  { value: "Liver", label: "Liver" },
  { value: "Cholesterol", label: "Cholesterol" },
  { value: "Liver Crystals", label: "Liver Crystals" },
  { value: "Kidney Crystals", label: "Kidney Crystals" },
  { value: "Parasites", label: "Parasites" },
  { value: "Candida", label: "Candida" },
  { value: "Fermentation", label: "Fermentation" },
  { value: "Hormones", label: "Hormones" },
  { value: "Digestion", label: "Digestion" },
  { value: "Lack of Vitamins", label: "Lack of Vitamins" },
  { value: "B12", label: "B12" },
  { value: "Iron", label: "Iron" },
  { value: "D3", label: "D3" },
  { value: "Minerals", label: "Minerals" },
  { value: "Clots", label: "Clots" },
  { value: "CVD Percentage", label: "CVD Percentage" },
  { value: "Immune System", label: "Immune System" },
];
export const Units = [
  { value: "g", label: "g" },
  { value: "kg", label: "kg" },
  { value: "ml", label: "ml" },
  { value: "l", label: "l" },
  { value: "tsp", label: "tsp" },
  { value: "tbsp", label: "tbsp" },
  { value: "cup", label: "cup" },
  { value: "pint", label: "pint" },
  { value: "quart", label: "quart" },
  { value: "gallon", label: "gallon" },
  { value: "oz", label: "oz" },
  { value: "lb", label: "lb" },
  { value: "mg", label: "mg" },
  { value: "mcg", label: "mcg" },
  { value: "unit", label: "unit" },
  { value: "quantity", label: "quantity" },
];

type Ingredient = { ingredientName: string };

export type IngredientUsage = {
  quantity: number;
  unit?: Unit;
  description?: string;
} & Ingredient;

export type Tip = {
  id: string;
  name: string;
  description: string;
};

export type Recipe = {
  id: string;
  name: string;
  ingredients: IngredientUsage[];
  tags?: DiagnosisTags[];
  instructions?: string;
  method?: string;
  //Todo: add maybe later for the purpose of filtering
  labels?: string[];
  dateCreated?: string;
  dateModified?: string;
};

export type PrescriptionSample = {
  id: string;
  instructions?: string[];
  type: "Breakfast" | "Lunch" | "Dinner" | "Snack";
  recipe: Array<Recipe & { duration: string }>;
  name?: string;
  description: string;
};
