import React, { useEffect, useState } from "react";
import { InputField, LabelText } from "../../common/components";
import Button from "../../common/components/button";
import { Table, TableRow } from "../../common/components/table";
import CSS from "./reports.module.css";
import { sampleReports as data } from "../../../data/sample-reports";
import { Reports } from "./pages/sections";
import Modal from "../../common/modal";

export const ReportsHome = () => {
  const [sampleReports, setSampleReports] = useState(data);
  const [filteredReports, setFilteredReports] = useState(sampleReports);

  const [showModal, setShowModal] = useState(false);

  //CLearing any edit patient data to be on the safe side
  useEffect(() => {}, []);

  return (
    <>
      <div className={CSS.form}>
        <div className={CSS.allRecipesHeader}>
          <span className={CSS.formField}>
            <LabelText text="Search" />
            <InputField
              width={400}
              placeholder="Search for reports (Patient Name or Blood group)"
              onChange={(value) => {
                if (value.target.value && value.target.value !== "") {
                  const filtered = filteredReports;
                  setFilteredReports(filtered);
                } else setFilteredReports(sampleReports);
              }}
            />
          </span>
          <Button
            label="New Report"
            onClick={() => {
              setShowModal(true);
            }}
          />
        </div>
        <Table>
          <TableRow
            isHeader
            columns={[
              { data: "Sr#", width: 3 },
              { data: "Name", width: 15 },
              { data: "Age", width: 5 },
              { data: "Blood Type", width: 5 },

              { data: "Phone Number", width: 10 },
              { data: "Email", width: 15 },
              { data: "Address", width: 10 },
              { data: "Referred By", width: 10 },
              { data: "Patient Since", width: 10 },
              { data: "Actions", width: 5 },
            ]}
          />
          {filteredReports.map((report, index) => (
            <span onClick={() => {}}>
              <TableRow
                key={report.id}
                columns={[
                  { data: index + 1, width: 3 },
                  { data: report.patient.name, width: 15 },
                  { data: report.patient.age, width: 5 },
                  { data: report.patient.bloodType, width: 5 },

                  { data: report.patient.phoneNumber, width: 10 },
                  { data: report.patient.email, width: 15 },
                  { data: report.patient.address, width: 10 },
                  { data: report.patient.referredBy, width: 10 },
                  { data: "01-01-2021", width: 10 },
                  // Todo: make a updated version of button to have a smaller size
                  { child: <Button label="Details" />, width: 5 },
                ]}
              />
            </span>
          ))}
        </Table>
      </div>
      {showModal && <Reports />}
    </>
    // <div className={CSS.form}>
    //   <Reports />
    // </div>
  );
};
