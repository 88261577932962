import React from "react";
import CSS from "./dashboard.module.css";
import { Sidebar } from "./sidebar";

import { Home } from "../sections/home";
import { Patients } from "../sections/patients";
import { Recipes } from "../sections/recipes";
import ViewRecipes from "../sections/recipes/view-recipes";
import { DashboardContext, SectionsType } from "./dashboard-context";
import { useContext } from "react";
import { AddPatient } from "../sections/patients/add-patient";
import { ReportsHome } from "../sections/reports";

export const Dashboard = () => {
  const { activeSection } = useContext(DashboardContext);

  return (
    <div className={CSS.layout}>
      <Sidebar />
      <div className={CSS.content}>
        <div className={CSS.body}>
          <div className={CSS.header}>
            <h1>{activeSection}</h1>
            <div className={CSS.horizontalDivider} />
          </div>
          <div className={CSS.section}>{returnSection(activeSection)}</div>
        </div>
      </div>
    </div>
  );
};

function returnSection(section: SectionsType) {
  switch (section) {
    case "Home":
      return <Home />;
    case "Patient Records":
      return <Patients />;
    case "Patient Logs":
      return <div>Patients Logs</div>;
    case "Appointments":
      return <div>Appointments</div>;
    case "Recipes":
      return <ViewRecipes />;
    case "Settings":
      return <div>Settings</div>;
    case "add-recipes":
    case "edit-recipes":
      return <Recipes />;

    case "add-patient":
    case "edit-patient":
      return <AddPatient />;
    default:
      return <ReportsHome />;
  }
}
