import React from "react";
import { AllPatients } from "./all-patients";

export const Patients = () => {
  return (
    <div>
      <AllPatients />
    </div>
  );
};
