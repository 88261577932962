import { useContext, useEffect, useState } from "react";
import { ReportsContext } from "../reports-context";
import Modal from "../../../common/modal";
import { AllPatients } from "../../patients/all-patients";
import CSS from "../reports.module.css";
import { InputField, LabelText } from "../../../common/components";
import Select from "react-select";
import { RecipeTagsOptions } from "../../../../types/recipes";
import { darkThemeStyles } from "./sections";
import { Field, useFormikContext } from "formik";

export const PatientInformation = () => {
  const { patient, setPatient } = useContext(ReportsContext);
  const [showModal, setShowModal] = useState(false);

  const { setFieldValue } = useFormikContext();

  type IngredientType = { value: string; label: string };

  const [selectedTags, setSelectedTags] = useState<IngredientType[]>([]);

  useEffect(() => {
    setShowModal(false);
    setFieldValue("patient", patient);
  }, [patient]);

  return (
    <>
      <div className={CSS.selectButtonContainer}>
        <span>
          {patient && (
            <h3>
              {patient.name} - {patient.age} Y - {patient.bloodType}
            </h3>
          )}
        </span>
        <span
          onClick={() => {
            setShowModal(true);
          }}
          className={CSS.textButton}
        >
          {!patient ? "Select Patient" : "Change Patient"}
        </span>
      </div>
      {showModal && (
        <div>
          <Modal
            onClose={() => setShowModal(false)}
            onCloseText="Close Patients"
            variant="small"
          >
            <AllPatients setPatient={setPatient} pageMode="patient-report" />
          </Modal>
        </div>
      )}

      {patient && (
        <>
          <span className={CSS.basicField}>
            <LabelText text="Diagnostics or Findings" />
            <Select
              isMulti
              onChange={(value) => {
                setSelectedTags(value as IngredientType[]);
                setFieldValue(
                  "diagnosis",
                  value.map((v) => v.value)
                );
              }}
              options={RecipeTagsOptions}
              styles={darkThemeStyles}
              value={selectedTags.length > 0 ? selectedTags : undefined}
            />
          </span>
          <span className={CSS.basicField}>
            <LabelText text="Notes" />
            <Field multiline placeholder="" as={InputField} name="notes" />
          </span>
        </>
      )}
    </>
  );
};
